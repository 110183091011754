.AppReport {
  .buttonsWrapper {
    display: flex;
    justify-content: flex-end;

    .button {
      display: flex;
      align-items: center;
      padding: 0 16px;
      margin: 0 0 20px 20px;
      background-color: #4c9742;
      border-radius: 5px;
      border: none;
      color: #fff;
      outline: none;
      cursor: pointer;
      font-size: 14px;
      font-weight: 600;
      opacity: 1;
      transition: background 0.3s ease-out, opacity 0.3s ease-out;
      height: 40px;
      text-decoration: none;
    }
  }

  input {
    width: 300px;
  }

  .checkbox {
    cursor: pointer;
    display: inline-block;

    input {
      width: auto;
    }
  }

  .optional {
    margin-top: 20px;
  }
  .item {
    margin-top: 10px;
  }

  .output {
    margin-top: 40px;
    background-color: rgb(240, 240, 240);
    padding: 12px 16px;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
}
