.buttonsWrapper {
  display: flex;
  justify-content: flex-end;
}

.rowsWrapper {
  margin-top: 50px;
}

.row {
  display: flex;
  border-bottom: 1px solid #d5ddea;
  background-color: #d8ffed;
  min-height: 40px;
  align-items: center;
  padding: 0 20px;

  &:first-child {
    border-top: 1px solid #d5ddea;
  }

  &.error {
    background-color: #ffd7dc;
  }

  .name,
  .status {
    flex-basis: 30%;
    min-width: 200px;
    max-width: 300px;
    margin-right: 20px;
  }

  .status {
    max-width: 400px;
  }

  > select,
  > button {
    border: none;
    height: 32px;
    cursor: pointer;
    background-color: #abf3d1;

    &:hover {
      background-color: #abf3d182;
    }
  }

  > select {
    padding: 0 5px;
    width: 300px;
    margin-right: 4px;

    &:focus {
      outline: none;
    }
  }

  > button {
    &:active {
      background-color: #abf3d1;
    }
    &:disabled {
      cursor: not-allowed;
      &:hover {
        background-color: #abf3d1;
      }
    }
  }
}

.checkbox,
input {
  cursor: pointer;
}
