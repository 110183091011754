.button {
  padding: 12px 16px;
  margin: 0 0 20px 20px;
  background-color: #d7282f;
  border-radius: 5px;
  border: none;
  color: #fff;
  outline: none;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  opacity: 1;
  transition: background 0.3s ease-out, opacity 0.3s ease-out;
  height: 40px;
  font-family: "Lato", "Proxima Nova", -apple-system, BlinkMacSystemFont,
    "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;

  &:hover {
    opacity: 0.8;
  }

  &:active {
    opacity: 0.6;
  }
}
