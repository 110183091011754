.tabs {
  display: flex;

  .tab {
    margin-left: 24px;
    padding: 8px;
    border-bottom: 2px solid rgba(215, 40, 47, 0.4);
    cursor: pointer;

    &:first-child {
      margin-left: 0;
    }

    &.active {
      border-color: rgb(215 40 47);
    }
  }
}
